/* src/index.css or src/styles/globals.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "serif", "Neuzeit Grotesk", "sans-serif";
}
